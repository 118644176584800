load();

function load() {
	var	$window = $(window),
		$banner		= $('#banner'),
		$slider		= $('#slider'),
		$body 		= $('body'),
		$nav			= $('#nav'),
		settings	= {
			banner: {
				indicators: true,
				speed: 1500,
				delay: 8000
			}
		};

		/**
		 * Custom banner slider for Zenith.
		 * @return {jQuery} jQuery object.
		 */
			$.fn._slider = function(options) {

				var	$window = $(window),
					$this = $(this);

				if (this.length == 0)
					return $this;

				if (this.length > 1) {

					for (var i=0; i < this.length; i++)
						$(this[i])._slider(options);

					return $this;

				}

				// Vars.
					var	current = 0, pos = 0, lastPos = 0,
						slides = [], indicators = [],
						$indicators,
						$slides = $this.children('article'),
						intervalId,
						isLocked = false,
						i = 0;

				// Turn off indicators if we only have one slide.
					if ($slides.length == 1)
						options.indicators = false;

				// Functions.
					$this._switchTo = function(x, stop) {

						if (isLocked || pos == x)
							return;

						isLocked = true;

						if (stop)
							window.clearInterval(intervalId);

						// Update positions.
							lastPos = pos;
							pos = x;

						// Hide last slide.
							slides[lastPos].removeClass('top');

							if (options.indicators)
								indicators[lastPos].removeClass('visible');

						// Show new slide.
							slides[pos].addClass('visible').addClass('top');

							if (options.indicators)
								indicators[pos].addClass('visible');

						// Finish hiding last slide after a short delay.
							window.setTimeout(function() {

								slides[lastPos].addClass('instant').removeClass('visible');

								window.setTimeout(function() {

									slides[lastPos].removeClass('instant');
									isLocked = false;

								}, 100);

							}, options.speed);

					};

				// Indicators.
					if (options.indicators)
						$indicators = $('<ul class="indicators"></ul>').appendTo($this);

				// Slides.
					$slides
						.each(function() {

							var $slide = $(this),
								$img = $slide.find('img');

							// Slide.
								$slide
									.css('background-image', 'url("' + $img.attr('src') + '")')
									.css('background-position', ($slide.data('position') ? $slide.data('position') : 'center'));

							// Add to slides.
								slides.push($slide);

							// Indicators.
								if (options.indicators) {

									var $indicator_li = $('<li>' + i + '</li>').appendTo($indicators);

									// Indicator.
										$indicator_li
											.data('index', i)
											.on('click', function() {
												$this._switchTo($(this).data('index'), true);
											});

									// Add to indicators.
										indicators.push($indicator_li);

								}

							i++;

						});

				// Initial slide.
					slides[pos].addClass('visible').addClass('top');

					if (options.indicators)
						indicators[pos].addClass('visible');

				// Bail if we only have a single slide.
					if (slides.length == 1)
						return;

				// Main loop.
					intervalId = window.setInterval(function() {

						current++;

						if (current >= slides.length)
							current = 0;

						$this._switchTo(current);

					}, options.delay);

			};


	// Play initial animations on page load.
		$window.on('load', function() {
			window.setTimeout(function() {
				$body.removeClass('is-preload');
			}, 100);
		});

	// Touch?
		if (browser.mobile)
			$body.addClass('is-touch');

	// Scrolly.
		$('.scrolly').scrolly({
			speed: 1500,
			offset: 100
		});

	// Banner.
		$banner._slider(settings.banner);

	// Dropdowns.
		$('li#locales > ul')
			.dropotron({
				//alignment: 'left',
				hideDelay: 350
			});

	// Menu.
		$('#menu')
			.prepend('<br>')
			.append('<a href="#menu" class="closer"></a>')
			.appendTo($body)
			.panel({
				delay: 500,
				hideOnClick: true,
				hideOnSwipe: true,
				resetScroll: true,
				resetForms: true,
				side: 'right',
				target: $body,
				visibleClass: 'menu-visible'
			});

}
